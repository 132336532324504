const _theme = {
  colors: {
    bodyBackground: '#f2f2f2',
    primary: '#51b148',
    primaryLight: 'rgba(0, 158, 15, 0.85)',
    primaryExtraLight: 'rgba(0, 158, 15, 0.25)',
    error: '#f44336',
    secondary: '#2660a4',
    warning: '#FA541C',
    white: '#fff',
    light: 'rgba(255, 255, 255, 0.65)',
    extraLight: 'rgba(255, 255, 255, 0.85)',
    extraDark: 'rgba(0, 0, 0, 0.85)',
    dark: 'rgba(0, 0, 0, 0.65)',
    darkLight: 'rgba(0, 0, 0, 0.25)',
    blue: '#2B78E4',
    red: '#CF2A27',
  },
  paddingUnit: 8,
  footerHeight: 64,
  headerHeight: 56,
}
